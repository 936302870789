import { captureException, setTags, setUser, captureMessage } from "@sentry/nextjs";
import { Locales, PaymentMethod } from "@simplyk/common";
import { StripeError } from "@stripe/stripe-js";

import { FormType, OrganizationObject } from "../gql/gql-types";

export const setSentryUser = (
  user: Partial<{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    locale: Locales;
    sessionId: string;
  }>
) => {
  setUser({
    id: user.id,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    locale: user.locale,
    session_id: user.sessionId,
    ip_address: "{{auto}}",
  });
};

export const setSentryOrganizationTags = (organization: OrganizationObject) => {
  setTags({
    organization_id: organization.id,
    country: organization.country,
  });
};

export const setSentryFormTags = (form: { id: string; type: FormType }) => {
  setTags({
    form_id: form.id,
    form_type: form.type,
  });
};

export const captureSentryError = ({
  message,
  paymentMethodType,
  params,
  error,
  tags,
}: {
  message: string;
  paymentMethodType?: PaymentMethod;
  params?: Record<string, unknown>;
  error?: StripeError | Error | string;
  tags?: Record<string, unknown>;
}) => {
  return captureException(new Error(message), {
    extra: { params, error },
    tags: { ...tags, payment_method_type: paymentMethodType, overrideFrontendLog: "true" },
  });
};

export const captureSentryMessage = ({ message, params }: { message: string; params?: Record<string, string> }) => {
  captureMessage(message, {
    tags: { ...params, overrideFrontendLog: "true" },
  });
};

export const captureSentryPaymentError = ({
  message,
  operation,
  paymentMethodType,
  params,
  error,
}: {
  message: string;
  operation?: "payment_cancelled" | "payment_failed";
  paymentMethodType?: PaymentMethod;
  params?: Record<string, unknown>;
  error?: StripeError | Error | string;
}) => captureSentryError({ message, paymentMethodType, params, error, tags: { operation } });

export const captureSentryFrontendCrash = ({
  message,
  error,
  extraTags = {},
}: {
  message: string;
  error: unknown;
  extraTags?: Record<string, unknown>;
}) => {
  return captureException(error instanceof Error ? error : new Error(message), {
    tags: {
      crash_frontend: "true",
      ...extraTags,
    },
    extra: {
      originalError: error,
      originalMessage: message,
    },
  });
};
