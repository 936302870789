import React, { createContext, useCallback, useContext, useRef } from "react";

import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

type QueryStateOperation = {
  key: string;
  value: string | null;
  history: "push" | "replace";
  shallow: boolean;
  scroll: boolean;
};

type QueryStateContextType = {
  enqueueRouterOperation: (operation: QueryStateOperation) => void;
};

const QueryStateContext = createContext<QueryStateContextType>({
  enqueueRouterOperation: () => {},
});

export const useQueryStateContext = () => {
  const context = useContext(QueryStateContext);

  if (!context) {
    throw new Error("useQueryStateContext must be used within a QueryStateProvider");
  }

  return context;
};

/**
 * Context provider for managing URL query state operations.
 *
 * This provider batches multiple query parameter updates into a single router navigation,
 * improving performance when multiple parameters need to be updated simultaneously.
 * It uses a queue system to collect operations and flush them in the next tick.
 *
 * @example
 * // Wrap your app with the provider
 * <QueryStateContextProvider>
 *   <App />
 * </QueryStateContextProvider>
 *
 * // Use the context in components
 * const { enqueueRouterOperation } = useQueryStateContext();
 *
 * // Enqueue an operation to update query params
 * enqueueRouterOperation({
 *   key: 'search',
 *   value: 'test',
 *   history: 'push',
 *   shallow: true,
 *   scroll: false
 * });
 */
export const QueryStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const flushOperationTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const queryStateOperationQueue = useRef<QueryStateOperation[]>([]);

  const flushOperations = useCallback(() => {
    const queue = queryStateOperationQueue.current;
    flushOperationTimeout.current = null;
    queryStateOperationQueue.current = [];

    let history = "push";
    let scroll = false;
    let shallow = false;
    const params = new URLSearchParams(searchParams);

    queue.forEach((operation) => {
      // Last operation wins
      history = operation.history;
      scroll = operation.scroll;
      shallow = operation.shallow;

      if (operation.value) {
        params.set(operation.key, operation.value);
      } else {
        params.delete(operation.key);
      }
    });

    const method = history === "push" ? router.push : router.replace;

    const url = { pathname, query: { ...Object.fromEntries(params.entries()) } };

    method.call(router, url, undefined, {
      shallow,
      scroll,
    });
  }, [pathname, router, searchParams]);

  const scheduleFlushOperations = useCallback(() => {
    if (flushOperationTimeout.current) {
      clearTimeout(flushOperationTimeout.current);
    }
    flushOperationTimeout.current = setTimeout(flushOperations, 1);
  }, [flushOperations]);

  // In case multiple operations are enqueued in the same tick, add them all to a queue and flush them all at once
  const enqueueRouterOperation = useCallback(
    (operation: QueryStateOperation) => {
      const queue = queryStateOperationQueue.current;
      queue.push(operation);

      scheduleFlushOperations();
    },
    [scheduleFlushOperations]
  );

  return <QueryStateContext.Provider value={{ enqueueRouterOperation }}>{children}</QueryStateContext.Provider>;
};
