/* eslint-disable @typescript-eslint/no-var-requires */

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { captureException } from "@sentry/nextjs";
import { RecaptchaGuardMessage } from "@simplyk/common";
import { SentryLink as ApolloSentryLink } from "apollo-link-sentry";
import { GraphQLError } from "graphql";
// eslint-disable-next-line no-restricted-imports
import { i18n } from "next-i18next";
import { enqueueSnackbar } from "notistack";

import { isDevelopment } from "../constants/env";

interface CustomGraphqlError extends GraphQLError {
  extensions: {
    exception?: {
      status: number;
      message?: string;
    };
  };
}

const recaptchaError = () => i18n?.t("common", "recaptchaError");

// LINK : Display errors on queries
const errorLink = onError((props) => {
  const { operation, graphQLErrors, networkError } = props;
  if (graphQLErrors) {
    for (const graphqlError of graphQLErrors) {
      const { extensions } = graphqlError as CustomGraphqlError;
      captureException(new GraphQLError(graphqlError.message), {
        tags: { operation_name: operation.operationName },
        extra: { variables: operation.variables },
      });

      // eslint-disable-next-line no-console
      console.log(`[GraphqlError error]: Error ${extensions?.exception?.status}`);

      if (extensions?.exception?.message === RecaptchaGuardMessage) {
        enqueueSnackbar(recaptchaError() || "", { vibe: "danger" });
      }
      //Runs only on client side
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (!(typeof window === "undefined")) {
        if (extensions?.exception?.status === 401) {
          // Dynamically importing to avoid any complications with route in auth.ts
          import("../helpers/auth").then(({ redirectAuthenticatedUserToLogout }) => {
            return redirectAuthenticatedUserToLogout({ connectedAsAdministrator: false });
          });
        }
      }
    }
  }

  if (networkError) {
    captureException(new GraphQLError(networkError.message), {
      tags: { operation_name: operation.operationName },
      extra: { variables: operation.variables },
    });
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const hasHttpsAgent = process.env.NEXT_PUBLIC_FORCE_SSL === "true" && isDevelopment;
// type-coverage:ignore-next-line
const httpsAgent = hasHttpsAgent ? require("https").Agent : undefined;

// LINK : Send the query to server (Final link)
const httpLink = new HttpLink({
  // type-coverage:ignore-next-line
  fetchOptions: httpsAgent
    ? {
        // type-coverage:ignore-next-line
        agent: new httpsAgent({ rejectUnauthorized: false, requestCert: true }), // for local https to work
      }
    : undefined,
  uri: process.env.NEXT_PUBLIC_API_URL + "/graphql",
  credentials: "include",
});
const link = ApolloLink.from([
  errorLink,
  new ApolloSentryLink({
    setTransaction: false,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      // Only allow when debugging locally
      includeCache: process.env.NODE_ENV !== "production",
    },
  }),
  httpLink,
]);

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link,
  ssrMode: typeof window === "undefined",
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    watchQuery: {
      errorPolicy: "all",
    },
  },
});
