/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppRouter } from "@/server/app/trpc/trpc.router";
import { RECAPTCHA_HEADERS } from "@simplyk/common";
import { httpLink, OperationLink, TRPCClientErrorLike, TRPCRequestOptions } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { AnyRouter } from "@trpc/server";
import { observable } from "@trpc/server/observable";

import { captureSentryMessage } from "./sentry";

export type AppRouterType = AppRouter;

// needed to fix a typing error because of the server import
declare module "express" {
  interface Request {
    user?: unknown;
  }
}

const errorLink = <TRouter extends AnyRouter>(): OperationLink<TRouter> => {
  return ({ next, op }) => {
    return observable((observer) => {
      return next(op).subscribe({
        next: (result) => {
          observer.next(result);
        },
        error: (err: TRPCClientErrorLike<TRouter>) => {
          if ((err.data as { httpStatus?: number })?.httpStatus === 401) {
            captureSentryMessage({
              message: "User has receive 401 from trpc",
              params: {
                path: op.path,
              },
            });
            // Dynamically importing to avoid any complications with route in auth.ts
            import("../helpers/auth").then(({ redirectAuthenticatedUserToLogout }) => {
              return redirectAuthenticatedUserToLogout({ connectedAsAdministrator: false });
            });
          }
          observer.error(err as never);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  };
};

const applyRecaptchaHeadersLink = <TRouter extends AnyRouter>(): OperationLink<TRouter> => {
  return ({ next, op }) => {
    return observable((observer) => {
      // Apply recaptcha headers to the request if they are present and remove them from the input
      if (op.input && typeof op.input === "object" && "recaptchaHeaders" in op.input) {
        const { recaptchaHeaders, ...rest } = op.input as TRPCRequestOptions & {
          recaptchaHeaders?: typeof RECAPTCHA_HEADERS;
        };
        op.context = {
          ...op.context,
          headers: {
            ...(op.context?.headers || {}),
            ...recaptchaHeaders,
          },
        };
        op.input = rest;
      }
      return next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
};

const dynamicHeadersLink = <TRouter extends AnyRouter>(): OperationLink<TRouter> => {
  return ({ op, next }) => {
    // Inject all other custom headers from input
    if (op.input && typeof op.input === "object" && "headers" in op.input) {
      const { headers, ...rest } = op.input as TRPCRequestOptions & {
        headers?: Record<string, string>;
      };
      op.context = {
        ...op.context,
        headers: {
          ...(op.context?.headers || {}),
          ...headers,
        },
      };
      op.input = rest;
    }
    // Pass the updated context to the next link
    return next(op);
  };
};

export const trpc = createTRPCNext<AppRouterType>({
  ssr: (opts): boolean => {
    // only SSR if the request is coming from a bot. https://trpc.io/docs/v10/client/nextjs/ssr
    return !!opts.ctx?.req?.headers["user-agent"]?.includes("bot");
  },
  config(opts) {
    const { ctx } = opts;
    return {
      links: [
        errorLink,
        applyRecaptchaHeadersLink,
        dynamicHeadersLink,
        httpLink({
          url: process.env.NEXT_PUBLIC_PROXY_API_URL + "/trpc",
          headers({ op }) {
            // Extract existing headers from context and add them to the request
            const contextHeaders = op.context?.headers as Record<string, string> | undefined;
            return {
              ...contextHeaders,
              cookie: !ctx?.req?.headers ? contextHeaders?.cookie : ctx.req.headers.cookie,
            };
          },
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      },
    };
  },
  responseMeta(opts) {
    const { clientErrors } = opts;
    if (clientErrors.length) {
      // propagate first http error from API calls
      return {
        status: clientErrors[0].data?.httpStatus ?? 500,
      };
    }
    // cache full page for 1 day + revalidate once every second
    const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
    return {
      "Cache-Control": `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
    };
  },
});
