import { PaletteMode } from "@mui/material";
import { PaletteColor } from "@mui/material/styles";
import { PrimaryMainColor } from "@simplyk/common";

import { LegacyZeffyColor, LegacyZeffyCorePalette, ZeffyColorTokens } from "@/types/mui";

export const DEFAULT_CONTRAST_COLOR = "#FFFFFF";
export const DEFAULT_REVERSE_COLOR = "#000000";

const neutral: LegacyZeffyColor = {
  100: "#FFFFFF",
  99: "#FCFCFC",
  95: "#F5F5F5",
  90: "#EDEDED",
  80: "#E0E0E0",
  70: "#C2C2C2",
  60: "#9E9E9E",
  50: "#757575",
  40: "#616161",
  30: "#424242",
  20: "#202020",
  10: "#0A0A0A",
  0: "#000000",
};

const neutralVariant: LegacyZeffyColor = {
  100: "#FAFAFC",
  99: "#F5F5F8",
  95: "#ECECF2",
  90: "#DDDDE8",
  80: "#C0C0D4",
  70: "#9B9ABA",
  60: "#7473A0",
  50: "#57568C",
  40: "#3F3E7C",
  30: "#27266B",
  20: "#0F0E5B",
  10: "#0D0C4C",
  0: "#0A093D",
};

const primaryAndInfoPalette: PaletteColor = {
  lightest: "#F8F8FF",
  lighter: "#EFEFFF",
  light: "#BFBFFF",
  main: PrimaryMainColor,
  dark: "#2A2AC4",
  darkest: "#00006D",
  contrastText: DEFAULT_CONTRAST_COLOR,
};

const secondaryAndSuccessPalette: PaletteColor = {
  lightest: "#F1FFFD",
  lighter: "#DEF7F3",
  light: "#84E7D7",
  main: "#09CFAF",
  dark: "#068A75",
  darkest: "#005143",
  contrastText: DEFAULT_CONTRAST_COLOR,
};

const tertiary: PaletteColor = {
  lightest: neutralVariant[95],
  lighter: neutralVariant[80],
  light: neutralVariant[50],
  main: neutralVariant[20],
  dark: neutralVariant[10],
  darkest: neutralVariant[0],
  contrastText: DEFAULT_CONTRAST_COLOR,
};

const error: PaletteColor = {
  lightest: "#FFF2F3",
  lighter: "#FFE0E2",
  light: "#FFBDC1",
  main: "#FF3947",
  dark: "#AA262F",
  darkest: "#551317",
  contrastText: DEFAULT_CONTRAST_COLOR,
};

const warning: PaletteColor = {
  lightest: "#FFF8EB",
  lighter: "#FFF1D6",
  light: "#FFE5B6",
  main: "#FFB424",
  dark: "#996B16",
  darkest: "#664914",
  contrastText: DEFAULT_CONTRAST_COLOR,
};

export const legacyCorePalette: Record<LegacyZeffyCorePalette, PaletteColor> = {
  primary: primaryAndInfoPalette,
  secondary: secondaryAndSuccessPalette,
  tertiary,
  info: primaryAndInfoPalette,
  success: secondaryAndSuccessPalette,
  error,
  warning,
};

export const zeffyColorTokens: ZeffyColorTokens = {
  text: {
    alternate: "#056757",
    brand: {
      intense: "#2A2AC4",
      moderate: "#5555E7",
      quiet: "#8181FF",
    },
    neutral: {
      intense: "#0A093D",
      moderate: "#0F0E5B",
      quiet: "#57568C",
      supershy: "#C0C0D4",
    },
    danger: {
      intense: "#850E16",
      moderate: "#AA262F",
      quiet: "#ED4A55",
    },
    warning: {
      intense: "#664914",
      moderate: "#966305",
      quiet: "#E19B12",
    },
    positive: {
      intense: "#005143",
      moderate: "#056757",
      quiet: "#08AC92",
    },
    form: {
      intense: "#000000",
      moderate: "#202020",
      quiet: "#757575",
      supershy: "#C2C2C2",
    },
    reverse: {
      intense: "#FFFFFF",
      moderate: "#F5F5F8",
      quiet: "#DDDDE8",
    },
  },
  surface: {
    alternate: "#84E7D7",
    oddity: "#1B1BB5",
    brand: {
      intense: "#5555E7",
      moderate: "#E0E0FF",
      quiet: "#F3F3FF",
    },
    neutral: {
      intense: "#0F0E5B",
      moderate: "#DDDDE8",
      quiet: "#ECECF2",
      supershy: "#F8F8FA",
      white: "#FFFFFF",
    },
    danger: {
      intense: "#E0000F",
      moderate: "#FFCFD2",
      quiet: "#FFEBEC",
    },
    warning: {
      intense: "#CC8500",
      moderate: "#F8DDAA",
      quiet: "#FFF3DB",
    },
    positive: {
      intense: "#08AC92",
      moderate: "#BAE5DE",
      quiet: "#E2F7F4",
    },
    form: {
      superintense: "#616161",
      intense: "#616161",
      moderate: "#C2C2C2",
      quiet: "#EDEDED",
      supershy: "#FCFCFC",
      white: "#FFFFFF",
    },
  },
  border: {
    alternate: "#8ED7CB",
    brand: {
      intense: "#5555E7",
      moderate: "#BFBFFF",
      quiet: "#E9E9FF",
    },
    neutral: {
      intense: "#57568C",
      moderate: "#C0C0D4",
      quiet: "#E5E5EE",
    },
    danger: {
      intense: "#FF3947",
      moderate: "#FFA7AD",
      quiet: "#FFE2E4",
    },
    warning: {
      moderate: "#F8D087",
      quiet: "#FFE9BF",
    },
    positive: {
      moderate: "#8ED7CB",
      quiet: "#D6F8F2",
    },
    form: {
      intense: "#757575",
      moderate: "#B9B9B9",
      quiet: "#E0E0E0",
    },
  },
};

export const palette = {
  mode: "light" as PaletteMode,
  background: {
    default: DEFAULT_CONTRAST_COLOR,
    reverse: DEFAULT_REVERSE_COLOR,
  },
  ...legacyCorePalette,
  alternative: {
    main: "#1B1BB5",
  },
  neutral,
  neutralVariant,
  ...zeffyColorTokens,
  text: {
    primary: neutralVariant[20],
    secondary: neutralVariant[50],
    ...zeffyColorTokens.text,
  },
};
