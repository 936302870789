export var SendEmailDeliveryError;
(function (SendEmailDeliveryError) {
    SendEmailDeliveryError["UnexpectedError"] = "UnexpectedError";
    SendEmailDeliveryError["SenderNoEmail"] = "SenderNoEmail";
    SendEmailDeliveryError["ManyRecipientsInPreview"] = "ManyRecipientsInPreview";
    SendEmailDeliveryError["LimitExceeded"] = "LimitExceeded";
    SendEmailDeliveryError["SendingFailed"] = "SendingFailed";
    SendEmailDeliveryError["StripeAccountNotActive"] = "StripeAccountNotActive";
    SendEmailDeliveryError["EmailDeliveryNotFound"] = "EmailDeliveryNotFound";
    SendEmailDeliveryError["FailedToGetSmartInviteRecipients"] = "FailedToGetSmartInviteRecipients";
    SendEmailDeliveryError["DesignConfigMissing"] = "DesignConfigMissing";
    SendEmailDeliveryError["FailedToExportUnlayerHtml"] = "FailedToExportUnlayerHtml";
    SendEmailDeliveryError["FailedToGetOwnerEmail"] = "FailedToGetOwnerEmail";
    SendEmailDeliveryError["FailedToGetReminderRecipients"] = "FailedToGetReminderRecipients";
    SendEmailDeliveryError["FailedToGetRecipients"] = "FailedToGetRecipients";
    SendEmailDeliveryError["FormNotFound"] = "FormNotFound";
})(SendEmailDeliveryError || (SendEmailDeliveryError = {}));
