import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ApplicationInitialStatusObject } from "../../gql/gql-types";

interface ApplicationInitialStatusState extends Omit<ApplicationInitialStatusObject, "__typename"> {
  loading: boolean;
}

const initialState = {
  loading: true,
  hasTicketing: false,
  hasActiveTicketing: false,
  hasArchivedTicketing: false,
  hasDonationForm: false,
  hasActiveDonationForm: false,
  hasArchivedDonationForm: false,
  firstForm: undefined,
  latestForm: undefined,
} as ApplicationInitialStatusState;

const applicationInitialStatusSlice = createSlice({
  name: "applicationInitialStatus",
  initialState,
  reducers: {
    load: {
      reducer: (_state, action: PayloadAction<ApplicationInitialStatusState>) => action.payload,
      prepare: (applicationInitialStatus: ApplicationInitialStatusObject) => {
        return {
          payload: {
            loading: false,
            ...applicationInitialStatus,
          },
        };
      },
    },
    skipApplicationInitialStatusLoad: (state) => ({
      ...state,
      loading: false,
    }),
    setHasDonationForm: (state, action: PayloadAction<boolean>) => ({ ...state, hasDonationForm: action.payload }),
    setHasTicketing: (state, action: PayloadAction<boolean>) => ({ ...state, hasTicketing: action.payload }),
    setHasActiveTicketing: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasActiveTicketing: action.payload,
    }),
    setHasActiveDonationForm: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasActiveDonationForm: action.payload,
    }),
  },
});

export const {
  load,
  skipApplicationInitialStatusLoad,
  setHasDonationForm,
  setHasTicketing,
  setHasActiveTicketing,
  setHasActiveDonationForm,
} = applicationInitialStatusSlice.actions;
export default applicationInitialStatusSlice.reducer;
