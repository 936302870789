/* eslint-disable sonarjs/no-duplicate-string */
import { TicketingFormCategory } from "./ticketing";
export var ReferralQuestionChoice;
(function (ReferralQuestionChoice) {
    ReferralQuestionChoice["ReferralFromOrganization"] = "ReferralFromOrganization";
    ReferralQuestionChoice["ReferralFromDonor"] = "ReferralFromDonor";
    ReferralQuestionChoice["SocialNetwork"] = "SocialNetwork";
    ReferralQuestionChoice["OrganicSearch"] = "OrganicSearch";
    ReferralQuestionChoice["Other"] = "Other";
    ReferralQuestionChoice["Capterra"] = "Capterra";
    ReferralQuestionChoice["AsDonor"] = "AsDonor";
    ReferralQuestionChoice["Nbctc"] = "Nbctc";
})(ReferralQuestionChoice || (ReferralQuestionChoice = {}));
export var SimplykProducts;
(function (SimplykProducts) {
    SimplykProducts["Donation"] = "Donation";
    SimplykProducts["Ticketing"] = "Ticketing";
})(SimplykProducts || (SimplykProducts = {}));
export var EmailContentType;
(function (EmailContentType) {
    EmailContentType["INVITATION"] = "invitation";
    EmailContentType["FOLLOW_UP"] = "followup";
    EmailContentType["BLAST"] = "blast";
    EmailContentType["POST_EVENT"] = "post-event";
    EmailContentType["REMINDER"] = "reminder";
})(EmailContentType || (EmailContentType = {}));
export var FormType;
(function (FormType) {
    FormType["DonationForm"] = "DonationForm";
    FormType["Ticketing"] = "Ticketing";
})(FormType || (FormType = {}));
export var OrganizationCause;
(function (OrganizationCause) {
    OrganizationCause["Nature"] = "Nature";
    OrganizationCause["Solidarity"] = "Solidarity";
    OrganizationCause["SportAndCulture"] = "SportAndCulture";
    OrganizationCause["Education"] = "Education";
})(OrganizationCause || (OrganizationCause = {}));
export var Ratio;
(function (Ratio) {
    Ratio[Ratio["Square"] = 0] = "Square";
    Ratio[Ratio["Landscape"] = 1] = "Landscape";
})(Ratio || (Ratio = {}));
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["Charity"] = "Charity";
    OrganizationType["Other501C"] = "Other501C";
    OrganizationType["Social"] = "Social";
    OrganizationType["Private"] = "Private";
    OrganizationType["Other"] = "Other";
})(OrganizationType || (OrganizationType = {}));
export var DonationFormCategory;
(function (DonationFormCategory) {
    DonationFormCategory["DonationForm"] = "DonationForm";
    DonationFormCategory["DonationCampaign"] = "DonationCampaign";
    DonationFormCategory["PeerToPeerCampaign"] = "PeerToPeerCampaign";
})(DonationFormCategory || (DonationFormCategory = {}));
export var FrequencyFilter;
(function (FrequencyFilter) {
    FrequencyFilter["Daily"] = "Daily";
    FrequencyFilter["Weekly"] = "Weekly";
    FrequencyFilter["Monthly"] = "Monthly";
})(FrequencyFilter || (FrequencyFilter = {}));
export const FormCategory = { ...TicketingFormCategory, ...DonationFormCategory };
export var EmailEditorOpenedFrom;
(function (EmailEditorOpenedFrom) {
    EmailEditorOpenedFrom["DuplicateFromTableRow"] = "DuplicateFromTableRow";
    EmailEditorOpenedFrom["DuplicateFromDrawer"] = "DuplicateFromDrawer";
    EmailEditorOpenedFrom["ContactPageHeader"] = "ContactPageHeader";
    EmailEditorOpenedFrom["ActivationCheckList"] = "ActivationCheckList";
    EmailEditorOpenedFrom["ContactPageEmailRow"] = "ContactPageEmailRow";
    EmailEditorOpenedFrom["CopyEmailInfobox"] = "CopyEmailInfobox";
    EmailEditorOpenedFrom["EmailTemplateWrapper"] = "EmailTemplateWrapper";
    EmailEditorOpenedFrom["PostThankYouEmailModal"] = "PostThankYouEmailModal";
    EmailEditorOpenedFrom["ContactPageBulkHeader"] = "ContactPageBulkHeader";
    EmailEditorOpenedFrom["CampaignPaymentPageBulkHeader"] = "CampaignPaymentPageBulkHeader";
    EmailEditorOpenedFrom["VisitorsTableRow"] = "VisitorsTableRow";
    EmailEditorOpenedFrom["VisitorsBulkHeader"] = "VisitorsBulkHeader";
    EmailEditorOpenedFrom["HomepageSuggestedFollowupTable"] = "HomepageSuggestedFollowupTable";
    EmailEditorOpenedFrom["CampaignFormsHeader"] = "CampaignFormsHeader";
    EmailEditorOpenedFrom["UserPageHeader"] = "UserPageHeader";
    EmailEditorOpenedFrom["EmailsHeader"] = "EmailsHeader";
    EmailEditorOpenedFrom["GuestListBulkHeader"] = "GuestListBulkHeader";
    EmailEditorOpenedFrom["GuestListRow"] = "GuestListRow";
    EmailEditorOpenedFrom["QuickActions"] = "QuickActions";
    EmailEditorOpenedFrom["InvitationEmailEditor"] = "InvitationEmailEditor";
    EmailEditorOpenedFrom["VisitorReport"] = "VisitorReport";
    EmailEditorOpenedFrom["EmailDeliveryEmpty"] = "EmailDeliveryEmpty";
    EmailEditorOpenedFrom["FullSuggestedActions"] = "FullSuggestedActions";
    EmailEditorOpenedFrom["ShareFormModal"] = "ShareFormModal";
    EmailEditorOpenedFrom["EventCommunication"] = "EventCommunication";
    EmailEditorOpenedFrom["EmailDeliveryDraftTableRow"] = "EmailDeliveryDraftTableRow";
    EmailEditorOpenedFrom["ScheduleInvitationEmailBanner"] = "ScheduleInvitationEmailBanner";
    EmailEditorOpenedFrom["GivingTuesdayShareModal"] = "GivingTuesdayShareModal";
    EmailEditorOpenedFrom["EmailDeliveryDraftEmpty"] = "EmailDeliveryDraftEmpty";
    EmailEditorOpenedFrom["PaymentDetailDrawer"] = "PaymentDetailDrawer";
    EmailEditorOpenedFrom["BlastEmailEditor"] = "BlastEmailEditor";
    EmailEditorOpenedFrom["EventCommunicationShortcut"] = "EventCommunicationShortcut";
    EmailEditorOpenedFrom["PostEventThankYouEmailModal"] = "PostEventThankYouEmailModal";
    EmailEditorOpenedFrom["TaxReceiptSuggestedActionModal"] = "TaxReceiptSuggestedActionModal";
    EmailEditorOpenedFrom["PaymentPageCampaignHubBulkHeader"] = "PaymentPageCampaignHubBulkHeader";
    EmailEditorOpenedFrom["CampaignTodo"] = "CampaignTodo";
    EmailEditorOpenedFrom["None"] = "None";
})(EmailEditorOpenedFrom || (EmailEditorOpenedFrom = {}));
export const isEmailEditorOpenedFrom = (value) => Object.values(EmailEditorOpenedFrom).includes(value);
export var SuggestedActionType;
(function (SuggestedActionType) {
    SuggestedActionType["Visitor"] = "Visitor";
    SuggestedActionType["TaxReceipt"] = "TaxReceipt";
    SuggestedActionType["ThankBigDonor"] = "ThankBigDonor";
    SuggestedActionType["AddPayment"] = "AddPayment";
    SuggestedActionType["Import"] = "Import";
    SuggestedActionType["AddUser"] = "AddUser";
    SuggestedActionType["TapToPay"] = "TapToPay";
    SuggestedActionType["FormCommunicationSetup"] = "FormCommunicationSetup";
})(SuggestedActionType || (SuggestedActionType = {}));
export var EmailContentFlow;
(function (EmailContentFlow) {
    EmailContentFlow["Custom"] = "Custom";
    EmailContentFlow["Quick"] = "Quick";
    EmailContentFlow["Builder"] = "Builder";
})(EmailContentFlow || (EmailContentFlow = {}));
export var FormSubmissionStatus;
(function (FormSubmissionStatus) {
    FormSubmissionStatus["Incomplete"] = "Incomplete";
    FormSubmissionStatus["Failed"] = "Failed";
    FormSubmissionStatus["Succeeded"] = "Succeeded";
})(FormSubmissionStatus || (FormSubmissionStatus = {}));
export var ContactSort;
(function (ContactSort) {
    ContactSort["LastName"] = "lower_last_name";
    ContactSort["LastTransactionDate"] = "contact.lastInteractionCreatedAt";
    ContactSort["TotalContribution"] = "contact.totalContribution";
})(ContactSort || (ContactSort = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ASC";
    SortOrder["DESC"] = "DESC";
})(SortOrder || (SortOrder = {}));
/*
  This enum is here to control the value of fieldName that is sent by the client
  We can avoid SQL injections that way since the fieldName cannot be passed as a parameter to the query
  Make sure that this value corresponds to a field alias in the corresponding typeorm query
*/
export var FieldName;
(function (FieldName) {
    FieldName["ContactTableUserId"] = "user.id";
    FieldName["ContactTableLanguage"] = "user.locale";
    FieldName["ContactTableEmail"] = "user.email";
    FieldName["ContactTableLastPaymentDate"] = "contact.lastInteractionCreatedAt";
    FieldName["ContactTableFirstPaymentDate"] = "contact.firstInteractionCreatedAt";
    FieldName["ContactTableTotalContribution"] = "contact.totalContribution";
    FieldName["ContactTableDonorType"] = "contact.donorType";
    FieldName["ContactTableCompleteName"] = "contact.fullname";
    FieldName["ContactTableCompanyName"] = "commands.corporationName";
    FieldName["ContactTableFormId"] = "commands.formId";
    FieldName["ContactTableTransactionDate"] = "transactions.createdAtUtc";
    FieldName["ContactTableMonthlyDonation"] = "subscriptions.status";
    FieldName["ContactTableRecurringPayment"] = "subscriptions.recurrenceInterval";
    FieldName["ContactTableTags"] = "tags.id";
    FieldName["ContactTableNoteContent"] = "notes.content";
    FieldName["TicketingTableIsArchived"] = "ticketing.is_archived";
    FieldName["DonationFormTableIsArchived"] = "donationForm.is_archived";
    FieldName["OrderTableCreateAtUtc"] = "order.created_at_utc";
    FieldName["OrderTableTicketingId"] = "order.ticketing_id";
    FieldName["OrderTableOccurrenceId"] = "order.occurrence_id";
    FieldName["OrderTableLocale"] = "order.locale";
    FieldName["OrderTableFirstName"] = "order.first_name";
    FieldName["OrderTableLastName"] = "order.last_name";
    FieldName["OrderTableEmail"] = "order.email";
    FieldName["OrderTableHasUnsubscribed"] = "contacts.hasUnsubscribed";
    FieldName["EmailDeliveryStatus"] = "email_deliveries.status";
    FieldName["CommandTableStatus"] = "command.status";
    FieldName["CommandTableCreatedAtUtc"] = "command.created_at_utc";
    FieldName["ReceiptTableCreatedAtUtc"] = "receipt.created_at_utc";
    FieldName["ReceiptTableDonationDate"] = "receipt.donation_date";
    FieldName["ReceiptTableFirstName"] = "receipt.first_name";
    FieldName["ReceiptTableLastName"] = "receipt.last_name";
    FieldName["ReceiptTableEmail"] = "receipt.email";
    FieldName["ReceiptTableStatus"] = "receipt.status";
    FieldName["AnswersAnswer"] = "answers.answer";
    FieldName["TransactionTableCreatedAtUtc"] = "transaction.created_at_utc";
    FieldName["TransactionTableFirstName"] = "command.first_name";
    FieldName["TransactionTableLastName"] = "command.last_name";
    FieldName["TransactionTableEmail"] = "command.email";
    FieldName["TransactionTableFormId"] = "command.form_id";
    FieldName["ProductTicketAnnotation"] = "product_tickets.annotation";
    FieldName["TransactionTableOccurrence"] = "command.ticketing_occurrence_id";
    FieldName["TransactionTableProductTicketStatus"] = "product_tickets.status";
    FieldName["TransactionTableHasUnsubscribe"] = "has_unsubscribed";
    FieldName["TransactionTableLocale"] = "command.locale";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    FieldName["HomePageTransactionCreatedAtUtc"] = "transaction.created_at_utc";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    FieldName["HomePageFormId"] = "command.form_id";
    FieldName["VisitorTableUserID"] = "command.user_id";
    FieldName["EventManagementTableCommandId"] = "command.id";
    FieldName["TicketStatus"] = "TicketStatus";
    FieldName["GuestListTableRate"] = "product_tickets.rate_id";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    FieldName["ProductTicketStatus"] = "product_tickets.status";
    FieldName["ReminderEmailStatus"] = "reminder_email.status";
})(FieldName || (FieldName = {}));
export var Sorting;
(function (Sorting) {
    Sorting["TitleAsc"] = "TitleAsc";
    Sorting["TitleDesc"] = "TitleDesc";
    Sorting["VolumeAsc"] = "VolumeAsc";
    Sorting["VolumeDesc"] = "VolumeDesc";
    Sorting["CreationDateDesc"] = "CreationDateDesc";
    Sorting["UpdatedDateDesc"] = "UpdatedDateDesc";
})(Sorting || (Sorting = {}));
export var PaymentSort;
(function (PaymentSort) {
    PaymentSort["Amount"] = "transferrable_amount";
    PaymentSort["Date"] = "transaction.created_at_utc";
    PaymentSort["PurchaserLastName"] = "lower_purchaser_last_name";
})(PaymentSort || (PaymentSort = {}));
export var FormStatus;
(function (FormStatus) {
    FormStatus["Draft"] = "draft";
    FormStatus["Active"] = "active";
})(FormStatus || (FormStatus = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Created"] = "created";
    PaymentStatus["Processing"] = "processing";
    PaymentStatus["Failed"] = "failed";
    PaymentStatus["Succeeded"] = "succeeded";
    PaymentStatus["AwaitingTransfer"] = "awaiting_transfer";
})(PaymentStatus || (PaymentStatus = {}));
export var DashboardPaymentStatus;
(function (DashboardPaymentStatus) {
    DashboardPaymentStatus["Processing"] = "Processing";
    DashboardPaymentStatus["Succeeded"] = "Succeeded";
    DashboardPaymentStatus["Failed"] = "Failed";
    DashboardPaymentStatus["Disputed"] = "Disputed";
    DashboardPaymentStatus["DisputeLost"] = "DisputeLost";
    DashboardPaymentStatus["Monthly"] = "Monthly";
    DashboardPaymentStatus["Yearly"] = "Yearly";
    DashboardPaymentStatus["Stopped"] = "Stopped";
    DashboardPaymentStatus["PastDue"] = "PastDue";
    DashboardPaymentStatus["Unprocessed"] = "Unprocessed";
    DashboardPaymentStatus["Incomplete"] = "Incomplete";
    DashboardPaymentStatus["OnHold"] = "OnHold";
})(DashboardPaymentStatus || (DashboardPaymentStatus = {}));
